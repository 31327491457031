import { ChevronLeftIcon, ChevronRightIcon } from "@radix-ui/react-icons";
import { useEffect, useMemo, useState } from "react";
import { useInstantSearch } from "react-instantsearch";
import { Box, Button, Flex, IconButton, Text } from "sparta";
import { useSearchV2PaginationLogic } from "~/utils/pagination";

export function Pagination({ pages, currentRefinement, nbPages, isFirstPage, isLastPage, refine }) {
  const { results } = useInstantSearch();
  const hitCount = new Intl.NumberFormat("en-US").format(results.nbHits);
  const visiblePages = useResponsivePages();

  const pagination = usePagination({
    nbPages,
    currentRefinement,
    pages,
    visiblePages,
  });

  const generatePagination = () => {
    return pagination.map((page, index) => {
      if (page === DOTS) {
        return (
          <IconButton key={page} variant="outline">
            {DOTS}
          </IconButton>
        );
      }
      return (
        <Button key={page} onClick={() => refine(page)} variant={page === currentRefinement ? "solid" : "outline"}>
          {page + 1}
        </Button>
      );
    });
  };

  return (
    <Flex
      width="100%"
      align={{ initial: "start", md: "center" }}
      justify="between"
      py="4"
      direction={{ initial: "column", md: "row" }}
    >
      <Text size="2">
        {hitCount} matching results in {results.processingTimeMS} ms
      </Text>
      <Flex align="center">
        <Button
          onClick={() => refine(currentRefinement - 1)}
          disabled={isFirstPage}
          leftIcon={<ChevronLeftIcon />}
          variant="outline"
        >
          Previous
        </Button>
        <div className="pages">{generatePagination()}</div>
        <Button
          onClick={() => refine(currentRefinement + 1)}
          disabled={isLastPage}
          rightIcon={<ChevronRightIcon />}
          variant="outline"
        >
          Next
        </Button>
      </Flex>
    </Flex>
  );
}

const useResponsivePages = () => {
  const [visiblePages, setVisiblePages] = useState(5);

  useEffect(() => {
    const updateVisiblePages = () => {
      const width = window.innerWidth;
      if (width < 480) {
        setVisiblePages(1);
      } else if (width < 768) {
        setVisiblePages(4);
      } else {
        setVisiblePages(7);
      }
    };

    window.addEventListener("resize", updateVisiblePages);
    updateVisiblePages();

    return () => window.removeEventListener("resize", updateVisiblePages);
  }, []);

  return visiblePages;
};

const usePagination = ({ nbPages, currentRefinement, pages: pagesB, visiblePages }) => {
  const pages = visiblePages === 1 ? [currentRefinement] : pagesB;
  const paginationRange = useMemo(
    () =>
      useSearchV2PaginationLogic({
        nbPages,
        currentRefinement,
        pages: pagesB,
        visiblePages,
      }),
    [nbPages, currentRefinement, pages],
  );

  return paginationRange;
};

const DOTS = "...";
